<template>
  <div class="lcf-assets-detail">
    <NavBar
      title="资产详情"
      left-arrow
      fixed
      @click-left="$router.replace({name: 'MyAssets', params: { status: 'current' }})"
      placeholder
    >
      <template #right>
        <div
          v-if="values.is_transferable === false"
          class="lcf-assets-detail-is-transferable"
          @click="alert"
        >
          <div>不可转让</div>
          <Icon name="question-o" size="16px"></Icon>
        </div>
      </template>
    </NavBar>

    <div class="lcf-assets-detail-p1">
      <div>{{ values.status_txt }}</div>
      <div>{{ values.amount }}</div>
      <div>持有金额（元）</div>
    </div>

    <div class="lcf-assets-detail-p2">
      <div class="lcf-assets-detail-p2-l">
        <div>
          <span>{{ values.apr }}</span
          >%
        </div>
        <div>约定年化借款利率</div>
      </div>
      <span class="van-hairline--left" style="height: 16px"></span>
      <div class="lcf-assets-detail-p2-r">
        <div>
          <span>{{ values.days }}</span>
        </div>
        <div>剩余期限（天）</div>
      </div>
    </div>

    <CellGroup>
      <Cell title="项目名称" :value="values.name" />
      <Cell title="所属集合" :value="values.ssjh" is-link @click="() => {}" />
      <Cell title="出借日期" :value="values.cjrq" />
      <Cell title="到期日期" :value="values.dqrq" />
      <Cell title="项目期限" :value="values.xmqx" />
      <Cell title="还款方式" :value="values.repay_type" />
      <Cell title="计息时间" :value="values.gain_type" />
      <Cell title="债权价值" :value="values.zqjz" />
      <Cell title="实际支付" :value="values.sjzf" />
      <Cell title="合作方" :value="values.hzf" />
    </CellGroup>
    <CellGroup>
      <Cell
        title="还款计划"
        is-link
        :to="{ name: 'RepayPlan', params: { id: values.invest_record_id } }"
      />
      <Cell
        title="债权转让协议"
        :value="values.agreements"
        :url="values.agreements_url"
      />
    </CellGroup>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { NavBar, Cell, CellGroup, Icon, Dialog, Notify } from 'vant'

export default {
  props: ['assets_id'],
  components: {
    NavBar,
    Cell,
    CellGroup,
    Icon,
  },
  data() {
    return {
      FIELD_MAP: null,

      values: {
        amount: '--',
        apr: '--',
        days: '--',
        //还款方式
        repay_type: '',
        status_txt: '',
        // 项目介绍
        name: '',
        // 起息时间
        gain_type: '',

        // 出借金额
        cjje: '',
        // 债权价值
        zqjz: '',
        // 实际支付
        sjzf: '',
        // 项目期限
        xmqx: '',
        // 出借日期
        cjrq: '',
        // 到期日期
        dqrq: '',
        // 所属集合
        ssjh: '',
        // 集合id
        project_set_id: '',
        // 合作方
        hzf: '',

        // 可否转让
        is_transferable: false,
        // 债权转让协议
        agreements: '',
        agreements_url: '',

        // 投资记录id
        invest_record_id: '',
      },
    }
  },
  mounted() {
    // debugger
    this.showMyAssetsDetail()
    // this.b()
    // this.c()

    this.FIELD_MAP = new Map(
      Object.entries({
        出借金额: 'cjje',
        债权价值: 'zqjz',
        实际支付: 'sjzf',
        项目期限: 'xmqx',
        出借日期: 'cjrq',
        到期日期: 'dqrq',
        所属集合: 'ssjh',
        合作方: 'hzf',
      })
    )

    this.$once('hook:beforeDestroy', function () {
      this.FIELD_MAP.clear()
    })
  },
  methods: {
    ...mapActions([
      'requestMyAssetsDetail',
      'requestMyAssetsRepays',
      'requestMyAssetsTransfers',
      'requestUserAddinDetail',
      'requestUserInvestRecord',
    ]),
    async showMyAssetsDetail() {
      let error, data
      ;[error, data] = await to(this.requestMyAssetsDetail(this.assets_id))

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data) {
        this.values.amount = data.top_data.amount
        this.values.apr = data.top_data.apr
        this.values.project_set_id = data.project_set_id
        this.values.is_transferable = data.is_transferable
        this.values.agreements = data.agreements
        this.values.agreements_url = data.agreements_url
        this.values.invest_record_id = data.invest_record_id
        this.values.days = data.top_data.days
        this.values.repay_type = data.top_data.repay_type
        this.values.status_txt = data.top_data.status_txt
        this.values.name = data.top_data.name
        this.values.gain_type = data.top_data.gain_type

        data.invest_data.forEach((item) => {
          let key = this.FIELD_MAP.get(item.name)

          this.values[key] = item.value
        })
      }

      return data
    },

    alert() {
      Dialog.alert({
        title: '温馨提示',
        message: '该项目不可转让',
      })
    },

    // async b() {
    //   let error, data
    //   ;[error, data] = await to(this.requestMyAssetsRepays(this.assets_id))

    //   // debugger
    // },
    // async c() {
    //   let error, data
    //   ;[error, data] = await to(
    //     this.requestMyAssetsTransfers({
    //       id: this.assets_id,
    //       page: 1,
    //       pageSize: 20,
    //     })
    //   )

    //   // debugger
    // },
    // async d(id) {
    //   let error, data
    //   ;[error, data] = await to(
    //     this.requestUserAddinDetail({
    //       id,
    //     })
    //   )

    //   // debugger
    // },
    // async e(setId) {
    //   let error, data
    //   ;[error, data] = await to(
    //     this.requestUserInvestRecord({
    //       page: 1,
    //       pageSize: 10,
    //       projectSetId: setId,
    //     })
    //   )

    //   debugger
    // },
  },
}
</script>

<style lang="scss" scoped>
.lcf-assets-detail {
  background: $lcf-color-bg-gray-light;
  .lcf-assets-detail-is-transferable {
    color: #fff;
    display: flex;
    flex-flow: row nowrap;
    div {
      padding: 0 2px;
      // display: flex;
      // align-items: center;
      line-height: 32px;
    }
    .van-icon {
      display: flex;
      align-items: center;
    }
  }
  .lcf-assets-detail-p1 {
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0 0 8px;
    color: $lcf-color-text-red;
    & > div:nth-child(1) {
      flex: 2 0;
      color: $lcf-color-text-red;
      padding: 2px 12px;
      border-radius: 12px;
      font-size: 12px;
      margin: 8px 0 0;
      background: $lcf-color-red-light;
    }
    & > div:nth-child(2) {
      flex: 3 0;
      line-height: 64px;
      font-size: 30px;
    }
    & > div:nth-child(3) {
      flex: 3 0;
      font-size: 12px;
      color: $lcf-color-text-gray;
      margin: 0 0 16px;
    }
  }
  .lcf-assets-detail-p2 {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background: #fff;
    margin: 0 0 8px;
    .lcf-assets-detail-p2-l,
    .lcf-assets-detail-p2-r {
      flex: 1 1;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      line-height: 24px;
      padding: 8px 0;
      div {
        font-size: 12px;
      }
      & > div:nth-child(1) {
        span {
          font-size: 16px;
        }
        color: $lcf-color-text-red;
        font-size: 12px;
      }
      & > div:nth-child(2) {
        color: $lcf-color-text-gray;
      }
    }
  }
}
</style>
